<script script="ts">
    import Footer from './_components/Footer.svelte';
    import Main from './_components/Main.svelte';
    import Nav from './_components/Nav.svelte';
</script>

<div class="d-flex flex-column sp-app-container sp-content-background">
    <Nav />
    <Main />
    <Footer />
</div>

<style>
    .sp-app-container {
        height: 100%;
        width: 100%;
    }</style>
