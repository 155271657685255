<script lang="ts">import { mdiArrowCollapseAll, mdiLaptop, mdiPackageVariant, mdiPrinterCheck, mdiSitemap, mdiTruckDelivery, } from '@mdi/js';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'sveltestrap';
import Carousel from './Carousel.svelte';
import ContactForm from './ContactForm.svelte';
const serviceList = [
    {
        id: 1,
        image: {
            alt: 'Services | Kitting - Assembly',
            name: 'services-kitting-assembly.jpg',
        },
        text: `Quality processes, quality communication, quality expectations not only met, but exceeded. You take 
                    pride in your products and we take pride in the satisfaction of a job well-done.`,
        title: 'Services | Kitting - Assembly',
    },
    {
        id: 2,
        image: {
            alt: 'Services | Custom Packaging',
            name: 'services-custom-packaging.jpg',
        },
        text: `FFE partners with packaging experts to design and source exactly what you are after.`,
        title: 'Services | Custom Packaging',
    },
    {
        id: 3,
        image: {
            alt: 'Services | Shrink Wrapping',
            name: 'services-shrink-wrapping.jpg',
        },
        text: `No matter the job size, FFE can handle all of your shrink wrapping needs.`,
        title: 'Services | Shrink Wrapping',
    },
    {
        id: 4,
        image: {
            alt: 'Services | Custom Marking',
            name: 'services-custom-marking.jpg',
        },
        text: `FFE provides a vast array of product Kitting & Assembly Services to meet the needs of our diverse 
                    customer base.`,
        title: 'Services | Custom Marking',
    },
    {
        id: 5,
        image: {
            alt: 'Services | Storage',
            name: 'services-storage.jpg',
        },
        text: `At last, there is a Warehousing storage solution where you only pay for what you use. Is your business 
                    seasonal? Do you store one pallet this month and 1,000 the next?`,
        title: 'Services | Storage',
    },
    {
        id: 6,
        image: {
            alt: 'Service | Fulfillment',
            name: 'services-fulfillment.jpg',
        },
        text: `Order Fulfillment processing just got easier. We take your orders in any form: Web, phone, fax, 
                    email, and EDI and get them shipped efficiently, accurately and cost-effectively.`,
        title: 'Services | Fulfillment',
    },
];
const technologyList = [
    {
        icon: mdiArrowCollapseAll,
        id: 1,
        text: `Integrate seamlessly with any marketplace and shopping cart. Our streamlined systems automatically 
                    synchronize your orders, inventory and returns, pushes your shipment status and tracking number 
                    and synchronizes your item and product data.`,
        title: 'Marketplace & Shopping Cart Integrations',
    },
    {
        icon: mdiTruckDelivery,
        id: 2,
        text: `Integrate effortlessly with Web, ERP and Accounting systems. FFE's WMS automatically captures
                    receiving and adjustment data, pushes back confirmations against purchase orders and captures
                    detailed transaction information.`,
        title: 'Full Transaction Detail',
    },
    {
        icon: mdiLaptop,
        id: 2,
        text: `Our web portal provides visibility into all aspects of your operation with real-time data,
                    dashboarding and reports that can be scheduled to email directly to your inbox.`,
        title: 'Full Transparency',
    },
    {
        icon: mdiPrinterCheck,
        id: 4,
        text: `Eliminate errors from your warehousing operations. FFE utilizes paperless warehousing through
                    mobile applications on handheld terminals and smartphones.`,
        title: 'Paperless Warehousing',
    },
    {
        icon: mdiPackageVariant,
        id: 5,
        text: `Execute picking, packing and shipment real time and fast through mobile applications, pick to
                    light hardware and conveyor automation. Streamlined pick, pack and ship operations are the
                    essentials of a reliable fulfillment operation.`,
        title: 'Pick, Pack and Ship Realtime',
    },
    {
        icon: mdiSitemap,
        id: 6,
        text: `Experience true multi-client and multi-warehouse operations. Utilize FFE's on-demand warehousing
                    services for storage, fulfillment, kitting and many other value added services.`,
        title: 'Multi-client and Multi-warehouse',
    },
];
</script>

<!-- Page Content -->
<main class="container">
    <Carousel />
    <Row id="services">
        <Col class="text-center">
            <h3 class="mt-5">SERVICES</h3>
            <hr />
            <Row>
                {#each serviceList as service}
                    <Col lg="4" sm="6" class="mb-4">
                        <Card class="h-100">
                            <img alt="{service.image.alt}" class="card-img-top" src="/images/{service.image.name}" />
                            <CardBody>
                                <CardTitle
                                    ><h4>
                                        {service.title}
                                    </h4>
                                </CardTitle>
                                <CardText>
                                    {service.text}
                                </CardText>
                            </CardBody>
                        </Card>
                    </Col>
                {/each}
            </Row>
        </Col>
    </Row>
    <Row id="technology">
        <Col class="text-center">
            <h3 class="mt-5">TECHNOLOGY</h3>
            <hr />
            <Row class="text-center justify-content-center mt-5">
                {#each technologyList as technology}
                    <Col xs="12" sm="6" class="mb-5">
                        <div class="align-items-center d-flex justify-content-center mx-auto mb-4 sp-feature-icon">
                            <svg viewBox="0 0 24 24">
                                <path fill="#000000" d="{technology.icon}"></path>
                            </svg>
                        </div>
                        <h3>{technology.title}</h3>
                        <p>{technology.text}</p>
                    </Col>
                {/each}
            </Row>
        </Col>
    </Row>
    <ContactForm />
</main>

<!-- Page Content -->
<style>
    main {
        margin-top: 80px;
    }

    .sp-feature-icon {
        background-color: #dd3333;
        height: 80px;
        width: 80px;
    }

    .sp-feature-icon svg {
        height: 48px;
        width: 48px;
    }

    .sp-feature-icon svg path {
        fill: white;
    }</style>
