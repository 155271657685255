<script lang="ts">import { mdiHomeMapMarker, mdiPhone } from '@mdi/js';
import { Col, Container, Row } from 'sveltestrap';
</script>

<footer class="d-flex sp-footer">
    <Container class="align-items-stretch d-flex flex-column flex-fill justify-content-center">
        <Row class="align-items-center d-flex flex-fill justify-content-center">
            <Col sm="3">
                <img
                    alt="FFE Solutions Group | Your number one partner"
                    class="sp-footer-logo"
                    src="/images/ffe_logo_v10-t.png"
                />
            </Col>
            <Col sm="3" class="d-flex">
                <div class="me-3">
                    <svg viewBox="0 0 24 24">
                        <path d="{mdiHomeMapMarker}" fill="#000000"></path>
                    </svg>
                </div>
                <div>
                    <h6 class="mb-2">Address</h6>
                    <address>
                        Headquarters<br />
                        1786 S 4650 W<br />
                        Salt Lake City, UT 84104<br />
                    </address>
                </div>
            </Col>
            <Col sm="3" class="d-flex">
                <div class="me-3">
                    <svg viewBox="0 0 24 24">
                        <path d="{mdiPhone}" fill="#000000"></path>
                    </svg>
                </div>
                <div>
                    <h6 class="mb-2">Phone</h6>
                    <p>1-801-980-0750</p>
                </div>
            </Col>
        </Row>
        <Row>
            <Col class="text-center">
                <p>Copyright &#xA9; 2024 | FFE Solutions Group All Rights Reserved.</p>
            </Col>
        </Row>
    </Container>
</footer>

<style>
    .sp-footer {
        background: #263238;
        color: #eceff1;
        font-size: 12px;
        min-height: 275px;
        width: 100%;
    }

    .sp-footer h6 {
        color: #fff;
        margin-bottom: 5px;
        min-height: 19px;
    }

    .sp-footer-logo {
        height: 120px;
        margin-top: -20px;
    }

    .sp-footer svg {
        height: 36px;
        width: 36px;
    }

    .sp-footer svg path {
        fill: white;
    }

    @media (max-width: 575.98px) {
        .sp-footer {
            min-height: 384px;
        }
    }</style>
