<script lang="ts">var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try {
            step(generator.next(value));
        }
        catch (e) {
            reject(e);
        } }
        function rejected(value) { try {
            step(generator["throw"](value));
        }
        catch (e) {
            reject(e);
        } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import * as yup from 'yup';
import API from '../../api';
import CustomForm from './ui/custom-form/CustomForm.svelte';
import FormFieldInput from './ui/form-field/FormFieldInput.svelte';
import FormFieldTextArea from './ui/form-field/FormFieldTextArea.svelte';
let errorMessage = '';
let formValues = {
    email: '',
    message: '',
    name: '',
    subject: '',
};
let formState;
let form;
let showThankyou = false;
const baseURL = 'https://ffe-api.azure-api.net/contactus';
const validationSchema = yup.object().shape({
    email: yup
        .string()
        .email('Email must be a valid email address')
        .required('Email is required.')
        .max(50, 'Email cannot exceed 50 characters.')
        .min(3, 'Email must be at least three characters.'),
    message: yup
        .string()
        .required('Message is required.')
        .max(800, 'Message cannot exceed 800 characters.')
        .min(3, 'Message must be at least three characters.'),
    name: yup
        .string()
        .required('Name is required.')
        .max(50, 'Name cannot exceed 50 characters.')
        .min(3, 'Name must be at least three characters.'),
    subject: yup
        .string()
        .required('Subject is required.')
        .max(50, 'Subject cannot exceed 50 characters.')
        .min(3, 'Subject must be at least three characters.'),
});
function onSubmit(formData) {
    return __awaiter(this, void 0, void 0, function* () {
        // console.log(values);
        // console.log(validationSchema);
        try {
            yield postContactForm(formData).then((response) => {
                console.log(response);
                showThankyou = true;
            });
        }
        catch (error) {
            errorMessage = 'Unauthorized';
        }
    });
}
function postContactForm(formData) {
    return __awaiter(this, void 0, void 0, function* () {
        try {
            const response = yield API.post(baseURL, formData);
            console.log(response);
            return response;
        }
        catch (error) {
            console.error(error);
            throw new Error(error);
        }
    });
}
</script>

<div class="row" id="contactus">
    <div class="col-lg-12">
        <!-- Masthead -->
        <div class="masthead text-white text-center">
            <div class="overlay"></div>
            <div class="container">
                <div class="row">
                    <div class="col-xl-9 mx-auto">
                        <h3 class="mb-5">CONTACT US</h3>
                        <p>We would love to hear from you...why not get in touch?</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="card my-5">
            <div class="card-body">
                {#if showThankyou === false}
                    <CustomForm
                        formValues="{formValues}"
                        bind:formState
                        onSubmit="{onSubmit}"
                        bind:this="{form}"
                        validationSchema="{validationSchema}"
                    >
                        <FormFieldInput label="Name" name="name" placeholder="Name (required)" />

                        <FormFieldInput label="Email" name="email" placeholder="Email (required)" type="email" />

                        <FormFieldInput label="Subject" name="subject" placeholder="Subject (required)" />

                        <FormFieldTextArea label="Message" name="message" placeholder="Message (required)" />

                        <button type="submit" class="btn btn-ar btn-success"> Submit </button>
                        <div class="clearfix"></div>
                    </CustomForm>
                {:else}
                    <div class="border rounded-sm">
                        <div class="p-3 mb-2 bg-success text-white">
                            <h3>Thank you for contacting FFE Solutions Group!</h3>
                        </div>
                        <div class="p-3 mb-2 bg-light text-dark">
                            <p>We will be in touch shortly!</p>
                        </div>
                    </div>
                {/if}
            </div>
        </div>
    </div>
</div>

<style>
    #contactus .card {
        box-shadow: none;
    }
    #contactus .card-body {
        padding: 0;
    }
    .masthead {
        height: 45vh;
        min-height: 350px;
        position: relative;
        background-color: #343a40;
        background: url('/images/ffe-solutions-group-contact-us.jpg') no-repeat center center;
        background-size: cover;
        padding-top: 8rem;
        padding-bottom: 8rem;
    }
    .masthead .overlay {
        position: absolute;
        background-color: #212529;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
    }
    .masthead h3 {
        font-size: 2rem;
    }
    @media (min-width: 768px) {
        .masthead {
            padding-top: 12rem;
            padding-bottom: 12rem;
        }
        
        .masthead h3 {
            font-size: 3rem;
        }
    }</style>
